import React from "react"
import News from "../../../blocks/en/news_page"
import { Link } from "gatsby"
import { graphql } from "gatsby"

export const pageQuery = graphql`
query {
  news: newsYaml(slug: { eq: "maksimov_vs_fifa" }) {
    ...NewsInfoEn
  }
}`

export default class NewsTextEn extends News {
  renderNews() {
    return (
      <div className="row">
        <div className="col-xl-12">
          <p><Link to={ `/en/players/maksimov` }>Maksim Maksimov</Link>, a former player of Fakel Voronezh, Trakai, Vardar and RFS, will resume his football career after Catenaccio Sports Agency, representing his interests, convinced the Sports Arbitration Court in Lausanne to declare the FIFA decision to disqualify the striker invalid.</p>
          <p>The decision concerned the transfer of Maximov from Trakai to Vardar in the summer of 2017. The forward terminated his agreement with Trakai in accordance with the contract terms a day before the closing of the transfer window and transferred to Vardar as a free agent.</p>
          <p>However, FIFA forbade the 23-year-old Russian, who for the long time in the 2017/18 season topped the list of scorers in the Europa League (7 goals), to play professional football as a result of a lawsuit filed by Moscow sports lawyer Mikhail Prokopets (SILA), representing the interests of the Lithuanian club.</p>
          <div className="video">
            <iframe
              src="https://www.youtube.com/embed/ZtDLQKa0of4"
              frameBorder="0"
              allow="autoplay; encrypted-media"
              allowFullScreen
            />
          </div>
          <p>Despite the resistance of FIFA and Prokopets, the Catenaccio Sports Agency was successful in lifting of the ban, convincing the Sports Arbitration Court to take the player’s side and reject the arguments of the other side. Now Maxim Maximov, who is in the sphere of interests of many Russian clubs, resumes his career.</p>
          <p>– We were utterly unhappy with the decision in the case of Maxim Maximov that FIFA originally issued and therefore without hesitation filed an appeal with CAS. After CAS canceled the FIFA decision today, I think that our opponents were also given to understand that the matter is not as unambiguous as they expected and what they talked about in public. I'm even of opinion that it could become an important precedent in world football”, commented Maxim Usanov, the head of the Catenaccio Sports Agency.</p>
        </div>
      </div>
    )
  }
}